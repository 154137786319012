export const API_URL = {
  TOKEN: 'token',
  DROPDOWN: 'dropdown',
  TEMPLATES: 'template',
  REPORT: 'report',
  CAMPAIGN: 'campaign',
  SCHEDULE: 'schedule',
  TEMPLATES_FILTERED_LIST: 'template/filtered-list',
  DASHBOARD_REPORTS: 'dashboard/reports',
  TEMPLATES_BY_DEPT: 'templates/by-department',
  MASTER_LANGUAGES: 'master/languages',
  MANUAL_CAMPAIGN_DATA: 'manual-campaign/data',
  SEND_REAL_TIME: 'campaign/send-realtime',
  EXPORT: 'export',
  GENERATE: 'generate',
  DOWNLOAD: 'download',
  MANUAL_CAMPAIGN_UPLOAD: 'manual-campaign/upload',
  UPLOAD_MEDIA: 'upload-media',
  REGISTER: 'register',
  MODIFY: 'modify',
  SEGMENT_LIST: 'segment/list',
  SEGMENT_FILTERED_LIST: 'segment/filtered-list',
  SCHEDULER_FILTERED_LIST: 'schedule/filtered-list',
  FILTERED_LIST: 'filtered-list',
  FORM_CONFIG: 'master/form-config',
  FORM_DECOMMISSION: 'decommission',
  ANSWER_TYPE: 'master/answer-type',
  QUESTION_REGISTER: 'question/register',
  language: 'languages',
  QUESTION_LIST: 'question/list',
  REORDER_QUESTION: 'question/reset-sequence',
  LIST: 'list',
  PLACEHOLDERS_MASTER: 'master/placeholders',
  FILE_LIST: 'file/list',
  FILE_HEADERS: 'file/headers',
  DELETE_QUESTION: 'question',
  QUESTION_COPY: 'question/copy',
  QUESTION_MAP: 'question/map',
  TEMPLATE_PLACEHOLDERS: 'template/placeholders',
  MASTER_ORGANIZATIONS: 'master/organizations',
  MASTER_VERTICALS: 'master/verticals',
  MASTER_DIVISIONS: 'master/divisions',
  MASTER_DEPARTMENTS: 'master/departments',
  TEMPLATE_LIST: 'template/list',
  EDIT_QUESTION: 'question/edit',
  UPDATE_SEQUENCE: 'question/set-sequence',
  UPDATE_SCRIPT: 'question/script',
  SCHEDULE_SEGMENT: 'schedule/segment',
  SEGMENT_SEARCH: 'master/segment-config',
  SCHEDULE_SEARCH: 'master/campaign-config',
  SEGMENT_REGISTER: 'segment/register',
  TEMPLATE_REGISTER: 'template/register',
  TEMPLATE_CONFIG: 'master/template-config',
  INITIATE: 'initiate',
  FORM_URL_PLACEHOLDERS: 'placeholders',
  QUESTION_COUNT: 'question/count',
  UPLOAD_TEMP_MEDIA: 'template/upload-media',
  UPLOAD_APPROVED_EMAIL: 'template/upload-approval',
  WABA_MASTER_CONFIG: 'master/waba-config',
  SAVE_WABA: 'waba/register',
  WABA_FILTERED_LIST: 'waba/filtered-list',
  MASTER_WABA_DETAILS: 'waba/details',
  UPDATE_WABA_STATUS: 'waba/status',
  TEST_WABA: 'waba/test',
  DOWNLOAD_APPROVAL: 'template/download-approval',
  UPDATE_TEMPLATE_STATUS: 'template/status',
  CHECK_TEMPLATE_EDIT_ACTION: 'editable',
  REFRESH_STATUS: 'template/refresh-status',
  MASTER_QUESTION_BANK: 'master/question-bank-group',
  REGISTER_LQS_GROUP: 'question/register-group',
  GET_DPS_JOB_LIST: 'master/job-details',
  FETCH_GROUP_QUESTIONS: 'question/info',
  EDIT_LQS_GROUP: 'question/edit-group',
  SCHEDULE_DETAILS: 'schedule/details',
  DOWNLOAD_AUDIT_TRAILS: 'schedule/audit-trail/download',
  DOWNLOAD_FAILED_QC_RECORDS: 'segment/qc-failed/download',
  TEST_TEMPLATE: 'placeholders',
  SAVE_TEST_TEMPLATE: 'notify',
  FILE_DETAILS: 'file-details',
  EMAIL_TEMPLATE_LAYOUTS: 'template/email-layouts',
  EMAIL_TEMPLATE_CONTENT: 'template/email-content',
  SAVE_EMAIL_TEMPLATE: 'template/email-info',
  MASTER_CACHE_RELOAD: 'master/cache-reload',
  TEMPLATE_EMAIL_MODIFY: 'template/email-modify',
};
