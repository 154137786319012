import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { loginClient } from 'common-partner-login-sdk/lib/esm';
import { Constants } from 'src/app/shared/utilities/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(private readonly router: Router) {
    const userData = localStorage.getItem(Constants.STORAGE_KEYS.ID_TOKEN);
    if (userData) {
      this.router.navigateByUrl('/templates');
    }
  }

  onLoginClick() {
    loginClient.signInWithFederation(environment.REDIRECT_URL);
  }
}
