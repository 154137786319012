import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { GridConfig, GridHeader } from '../common-grid/common-grid.interface';
import { LazyLoadEvent, SortEvent } from 'primeng/api';
import { matchModeOptionsForFilter } from 'src/app/core/constants/common';
import {
  ButtonType,
  GRID_DATA_TYPE,
} from '../common-grid/common-grid.constant';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnChanges {
  @Input() data: Array<any> = [];
  @Input() headers: Array<GridHeader> = [];
  @Output() buttonClickEvent: EventEmitter<any> = new EventEmitter();

  dataCopy: any[] = [];
  totalRecords = 0;
  sortingDirection: number;
  sortingField: string | undefined;
  pageSize = 10;
  gridConfig: GridConfig = {
    pageSizeOptions: [10, 20, 30],
  };
  matchModeOptions = matchModeOptionsForFilter;
  GRID_DATA_TYPE = GRID_DATA_TYPE;
  BUTTON_TYPE = ButtonType;

  constructor() {}

  ngOnChanges() {
    this.totalRecords = this.data.length;
    this.pageSize = 10;
  }

  onSort(event: SortEvent): void {
    this.sortingField = event.field;
    if (event.order === 1) {
      this.sortingDirection = 1;
    } else {
      this.sortingDirection = -1;
    }
    this.sortData();
  }

  sortData() {
    if (this.sortingField) {
      this.data.sort((a, b) => {
        const value1 = a[this.sortingField as keyof typeof a];
        const value2 = b[this.sortingField as keyof typeof b];
        let result = null;
        if (!value1 || !value2) result = -1;
        else if (value1 != null && value2 == null) result = 1;
        else if (value1 == null && value2 == null) result = 0;
        else if (this.isDate(value1) && this.isDate(value2)) {
          const date1 = new Date(value1);
          const date2 = new Date(value2);
          return this.sortingDirection * (date1.getTime() - date2.getTime());
        } else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
        else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        return this.sortingDirection * result;
      });
    }
  }

  isDate(value: any): boolean {
    return value instanceof Date || !isNaN(Date.parse(value));
  }

  onButtonClick(rowData: any, eventName: string) {
    this.buttonClickEvent.emit({ rowData: rowData, eventName: eventName });
  }

  onPageSizeChange(event: LazyLoadEvent): void {
    this.pageSize = event.rows!;
  }
}
