import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ButtonActionType,
  ChannelType,
} from '../../../modules/templates/interfaces/create-template.interface';
import { EXTENSION, MEDIA_TYPE } from 'src/app/core/constants/common';
import { Icons } from 'src/app/shared/utilities/icons';
import { MaxShowLetterCount } from '../../utilities/constants';

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss'],
})
export class TemplatePreviewComponent implements OnInit, OnChanges {
  @Input() channel: string;
  @Input() mediaURL?: string;
  @Input() content: string;
  @Input() footer: string;
  @Input() ctaButtons?: any[];
  @Input() isCarousel: boolean;
  @Input() carouselCards: any[];
  @Input() isCalledFromCreation = false;

  icons = Icons;
  mediaType = MEDIA_TYPE;
  buttonActionType = ButtonActionType;
  mediaFileType: string;
  isTextExpanded = false;
  channelType = ChannelType;
  maxLetterCount: number;
  carouselUICards: any[];

  ngOnInit(): void {
    this.maxLetterCount =
      this.channel === ChannelType.WHATSAPP
        ? MaxShowLetterCount.WHATSAPP
        : MaxShowLetterCount.SMS;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mediaURL']?.currentValue) {
      this.mediaURL = changes['mediaURL'].currentValue;
      if (this.mediaURL && this.mediaURL !== '#mediaUrl#') {
        this.setTypeAndExtension(this.mediaURL);
      }
    }
    if (changes['footer']?.currentValue?.length) {
      this.footer = changes['footer'].currentValue;
    }
    if (changes['ctaButtons']?.currentValue?.length) {
      this.ctaButtons = changes['ctaButtons'].currentValue;
    }
    if (changes['isCarousel']?.currentValue) {
      this.isCarousel = changes['isCarousel'].currentValue;
    }
    if (
      changes['carouselCards']?.currentValue?.length &&
      JSON.stringify(changes['carouselCards'].previousValue) !==
        JSON.stringify(changes['carouselCards']?.currentValue)
    ) {
      this.carouselCards = changes['carouselCards'].currentValue;
      this.carouselUICards = [...this.carouselCards];
      if (this.carouselUICards?.length && this.isCalledFromCreation) {
        this.modifyCarouselCards();
      }
    }
  }

  setTypeAndExtension(mediaURL: string): void {
    const extension = mediaURL.split('.').pop()?.toUpperCase();
    this.mediaFileType =
      extension === EXTENSION.MP4
        ? MEDIA_TYPE.VIDEO
        : extension === EXTENSION.PNG || extension === EXTENSION.JPEG
        ? MEDIA_TYPE.IMAGE
        : MEDIA_TYPE.DOC;
  }

  toggleText(): void {
    this.isTextExpanded = !this.isTextExpanded;
  }

  modifyCarouselCards(): void {
    this.carouselUICards = this.carouselCards.map((card) => {
      return {
        ...card,
        mediaURL: card.cardImage || '',
        content: card.cardText,
        mediaType: card.cardImage?.includes('.mp4')
          ? MEDIA_TYPE.VIDEO
          : MEDIA_TYPE.IMAGE,
        buttons: card.button?.map((cta: any) => {
          return {
            ...cta,
            buttonAction: cta.action.replace(/_/g, ' '),
          };
        }),
      };
    });
  }
}
