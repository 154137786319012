import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { AuthService } from '../authorization/auth.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { StorageService } from '../cache/storage.service';
import { CommonAuthConstants } from '../authorization/auth.constants';
import { PermissionService } from '../services/permission.service';
import { loginClient } from 'common-partner-login-sdk/lib/esm';
import { CommonMessages } from '../constants/common';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  private permissionCalled = false;

  constructor(
    private readonly authGuard: AuthService,
    private readonly commonService: CommonService,
    private readonly router: Router,
    private readonly permissionService: PermissionService,
    private readonly storageService: StorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authGuard.canActivate().pipe(
      switchMap((authenticated) => {
        if (authenticated !== true && !(authenticated instanceof UrlTree)) {
          this.router.navigateByUrl('login');
          return of(authenticated);
        }
        const permissionKey = `${CommonAuthConstants.common}:${route.data?.['key']}`;
        return this.resolvePermissions(permissionKey);
      }),
      catchError((error: HttpErrorResponse) => {
        this.commonService.showError(error);
        const url = this.commonService.getPreviousUrl();
        this.router.navigateByUrl(url !== '/' ? url : '/login');
        return of(false);
      })
    );
  }

  private resolvePermissions(value: string): Observable<boolean | UrlTree> {
    const permissionsSet = this.permissionService.getPermission();
    if (permissionsSet && permissionsSet.length > 0) {
      return of(this.checkAndResolvePermission(value));
    }
    return from(this.getUserPermissions()).pipe(
      map((permissionsResult) => {
        if (permissionsResult) {
          return this.checkAndResolvePermission(value);
        } else {
          this.handleUnauthorized(CommonMessages.ERROR.NOT_ENOUGH_PERMISSION);
          return false;
        }
      })
    );
  }

  getUserPermissions(): Promise<boolean> {
    if (this.permissionCalled) {
      return Promise.resolve(true);
    }
    return loginClient
      .getUserPermissions()
      .then((res: any) => {
        if (!res.error && res.data?.length > 0) {
          const permissionData = res.data.filter(
            (e: { active: boolean }) => e.active
          );
          this.permissionService.setPermission(permissionData);
          this.permissionCalled = true;
          return true;
        } else {
          return false;
        }
      })
      .catch(() => {
        this.commonService.showErrorMessage(
          CommonMessages.ERROR.SOME_ERROR_OCCURED
        );
        this.storageService.clear();
        this.router.navigateByUrl('/login');
        return false;
      });
  }

  private checkAndResolvePermission(value: string): boolean | UrlTree {
    if (this.checkPermission(value)) {
      this.storageService.loginLogout.next('login');
      return true;
    } else {
      this.handleUnauthorized(CommonMessages.ERROR.NOT_ENOUGH_PERMISSION);
      return false;
    }
  }

  private handleUnauthorized(message: string): void {
    this.commonService.showErrorMessage(message);
    const url = this.commonService.getPreviousUrl();
    this.router.navigateByUrl(url !== '/' ? url : '/login');
  }

  private checkPermission(value: string): boolean {
    return this.permissionService.checkPermission(value);
  }
}
