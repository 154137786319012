import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UploadFile,
  UploadInput,
  UploadOutput,
  UploaderOptions,
} from 'ngx-uploader';
import { EXTENSION, FILE_TYPE, UPLOAD } from 'src/app/core/constants/common';
import { CommonService } from '../../services/common.service';
import { MIME_TYPE } from 'src/app/core/constants/mime-types';
import { FileType } from 'src/app/modules/templates/interfaces/create-template.interface';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
  @Input() options: UploaderOptions;
  @Input() uploadType?: string;
  @Input() allowExtensions?: string[];
  @Input() fromFileUpload?: boolean;
  @Output() selectedFiles: EventEmitter<UploadFile[]> = new EventEmitter();
  @Output() reasonForRejection: EventEmitter<string> = new EventEmitter();

  files: UploadFile[] = [];
  uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();

  constructor(private readonly commonService: CommonService) {}

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.setFileType(output.file);
          const maxSize = this.getFileSize();

          if (output.file.size > maxSize) {
            this.emitSizeRejectionReason();
          } else {
            this.files.push(output.file);
            this.selectedFiles.emit(this.files);
          }
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex(
            (file) =>
              typeof output.file !== 'undefined' && file.id === output.file.id
          );
          this.files[index] = output.file;
        }
        break;
      case 'rejected':
        if (typeof output.file !== 'undefined') {
          if (this.fromFileUpload) {
            if (!this.options.allowedContentTypes?.includes(output.file.type)) {
              this.reasonForRejection.emit('This File Format is not supported');
              return;
            }
            if (
              output.file.size > (this.options.maxFileSize || UPLOAD.MAX_SIZE)
            ) {
              this.emitSizeRejectionReason();
              return;
            }
          } else {
            if (this.allowExtensions?.length) {
              const isExtMatched = this.allowExtensions?.some((ext) =>
                output.file?.name.endsWith(`.${ext.toLowerCase()}`)
              );

              if (isExtMatched) {
                this.files.push(output.file);
                this.selectedFiles.emit(this.files);
              } else {
                const maxSize = this.getFileSize();
                if (output.file.size > maxSize) {
                  this.emitSizeRejectionReason();
                } else {
                  this.emitFormatRejectionReason();
                }
              }
            } else {
              const isFileTypePresent =
                this.options.allowedContentTypes?.includes(output.file?.type);

              if (isFileTypePresent) {
                const maxSize = this.getFileSize();
                if (output.file.size > maxSize) {
                  this.emitSizeRejectionReason();
                } else {
                  this.files.push(output.file);
                  this.selectedFiles.emit(this.files);
                }
              } else {
                this.emitFormatRejectionReason();
              }
            }
          }
        }
        break;
      case 'done':
        // The file is downloaded
        break;
    }
  }

  setFileType(file: UploadFile): void {
    const fileType = file?.nativeFile?.type;
    switch (fileType) {
      case MIME_TYPE.PDF:
      case EXTENSION.PDF:
        this.commonService.mediaAttachment = FileType.DOCUMENT;
        break;
      case MIME_TYPE.CSV:
      case EXTENSION.CSV:
        this.commonService.mediaAttachment = FileType.DOCUMENT;
        break;
      case MIME_TYPE.DOC:
      case EXTENSION.DOC:
        this.commonService.mediaAttachment = FileType.DOCUMENT;
        break;
      case MIME_TYPE.XLSX:
      case EXTENSION.XLSX:
        this.commonService.mediaAttachment = FileType.DOCUMENT;
        break;
      case MIME_TYPE.PNG:
      case EXTENSION.PNG:
        this.commonService.mediaAttachment = FileType.IMAGE;
        break;
      case MIME_TYPE.JPEG:
      case MIME_TYPE.JPG:
      case EXTENSION.JPEG:
      case EXTENSION.JPG:
        this.commonService.mediaAttachment = FileType.IMAGE;
        break;
      case MIME_TYPE.MP4:
      case EXTENSION.MP4:
        this.commonService.mediaAttachment = FileType.VIDEO;
        break;
      default:
        this.commonService.mediaAttachment = FileType.IMAGE;
    }
  }

  getFileSize(): number {
    if (!this.uploadType) {
      this.uploadType = this.commonService.mediaAttachment;
    }
    return this.uploadType === FILE_TYPE.EMAIL
      ? UPLOAD.EMAIL_ATTACHMENT.MAX_SIZE
      : this.uploadType === FILE_TYPE.WHATSAPP_IMAGE
      ? UPLOAD.MEDIA_ATTACHMENT.IMAGE_MAX_SIZE
      : this.uploadType === FILE_TYPE.WHATSAPP_VIDEO
      ? UPLOAD.MEDIA_ATTACHMENT.VIDEO_MAX_SIZE
      : this.uploadType === FILE_TYPE.WHATSAPP_DOCUMENT
      ? UPLOAD.MEDIA_ATTACHMENT.DOC_MAX_SIZE
      : this.uploadType === FILE_TYPE.LOGO
      ? UPLOAD.FORM_ATTACHMENT.LOGO_MAX_SIZE
      : this.uploadType === FILE_TYPE.BANNER
      ? UPLOAD.FORM_ATTACHMENT.BANNER_MAX_SIZE
      : UPLOAD.TEMPLATE_ATTACHMENT.MAX_SIZE;
  }

  emitSizeRejectionReason(): void {
    this.reasonForRejection.emit(
      'Selected file size is greater than allowed file size'
    );
  }

  emitFormatRejectionReason(): void {
    this.reasonForRejection.emit('Selected file format is not supported');
  }
}
