<div class="modal-header" *ngIf="headerConfig">
  <span class="header-text">
    <i class="{{ headerConfig.icon }}" *ngIf="headerConfig.icon"></i>
    <h4 class="modal-title pull-left">{{ headerConfig.title || "" }}</h4>
  </span>
  <span
    *ngIf="headerConfig.isCloseBtn"
    class="close-icon"
    (click)="onCancelClick()"
    (keydown)="$event.preventDefault()"
  >
    &times;
  </span>
</div>
<ng-container *ngIf="bodyConfig">
  <section class="modal-content">
    <div *ngIf="bodyConfig.text">
      {{ bodyConfig.text || "" }}
    </div>
    <ng-container *ngIf="bodyConfig.elements?.length">
      <div *ngFor="let elem of bodyConfig.elements">
        <div *ngIf="elem.text" class="mt-3">
          {{ elem.text || "" }}
        </div>
        <ng-container [ngSwitch]="elem.type">
          <div *ngSwitchCase="'textarea'" class="my-2">
            <textarea
              [(ngModel)]="elem.response"
              rows="3"
              cols="3"
              class="form-control"
              pInputTextarea
              [maxLength]="elem.maxCharLength"
              (keyup)="onChangeTextArea(elem)"
            ></textarea>
            <p class="text-end">
              Characters: {{ elem.response?.length }}/{{ elem.maxCharLength }}
            </p>
            <small class="red-text mb-2" *ngIf="elem.error"
              >Special characters are not allowed.</small
            >
          </div>
          <div *ngSwitchCase="'copy_link'" class="form-url copy-link">
            <a href="{{ elem.response }}" target="_blank" rel="noopener">{{
              elem.response
            }}</a>
          </div>
          <div *ngSwitchCase="'mobile_number'" class="my-3 mobile-section">
            <input
              type="number"
              [(ngModel)]="elem.response"
              name="mobile_number"
              class="mobile-number"
              (input)="onInput($event)"
              (keydown)="typeMobileNo($event)"
            />
            <small
              class="red-text"
              *ngIf="
                elem?.response &&
                elem?.response?.toString()?.length !== elem.length
              "
              >Please enter {{ elem.length }} digit number.</small
            >
          </div>
        </ng-container>
      </div>
    </ng-container>
  </section>
</ng-container>

<div class="modal-footer mt-2" *ngIf="footerConfig">
  <button
    *ngIf="footerConfig.isCancelButton"
    type="button"
    class="btn btn-footer me-2"
    (click)="onCancelClick()"
  >
    {{ footerConfig.cancelButtonText || "Cancel" }}
  </button>
  <button
    type="button"
    class="btn btn-footer"
    *ngIf="footerConfig.isConfirmButton"
    [ngClass]="{
      disabled: isDisabled()
    }"
    [disabled]="isDisabled()"
    (click)="onConfirmClick()"
  >
    {{ footerConfig.confirmButtonText || "Confirm" }}
  </button>
</div>
