import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preview-form',
  templateUrl: './preview-form.component.html',
  styleUrls: ['./preview-form.component.scss'],
  providers: [ConfirmationService],
})
export class PreviewFormComponent implements OnInit {
  url: string = environment.FORM_PREVIEW_URL;
  urlSafe: SafeResourceUrl;
  formCode: string;
  @ViewChild('previewFormFrame') previewFormFrame: ElementRef;
  loadFrame = false;
  isPublishView: boolean;
  loading = true;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private readonly sanitizer: DomSanitizer,
    private readonly spinner: NgxSpinnerService,
    private readonly confirmationService: ConfirmationService,
    private readonly primengConfig: PrimeNGConfig,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.spinner.show();
    this.isPublishView = this.config.data.isPublishView;
    this.formCode = this.config.data.formCode;
    if (this.formCode) {
      this.loadFrame = true;
      const iframe = true;
      this.url = `${this.url}/form/${this.formCode}/PREVIEW/${iframe}`;
      /**NOSONAR*/ this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.url
      );
    }
  }

  onClose(action?: string): void {
    this.ref.close(action);
  }

  onIframeLoad(): void {
    this.loading = false;
    this.spinner.hide();
  }

  backToQuestionsList(): void {
    this.router.navigate([`/form-design/questionnaire/${this.formCode}`]);
    this.ref.close();
  }

  confirmationPopup(): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Publish the form?',
    });
  }
}
