<div class="preview-container" *ngIf="channelTypeImg">
  <div class="close" (click)="bsModalRef.hide()">
    <img src="{{ icons.CLOSE }}" alt="Close" />
  </div>
  <h2>{{ title }}</h2>
  <div class="preview-modal-body">
    <img src="{{ channelTypeImg }}" alt="Channel_Type" />
    <div class="preview">
      <app-template-preview
        [channel]="channelType"
        [mediaURL]="mediaURL"
        [content]="content"
        [footer]="footer"
        [ctaButtons]="ctaButtons"
        [isCarousel]="isCarousel"
        [carouselCards]="carouselCards"
      ></app-template-preview>
    </div>
  </div>
  <div class="preview-modal-footer">
    <button class="btn-back" (click)="bsModalRef.hide()">
      Back to Templates
    </button>
  </div>
</div>
