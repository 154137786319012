<div
  class="template-content-container"
  [ngClass]="{ SMS: channel === channelType.SMS }"
>
  <div class="main">
    <div
      class="media"
      *ngIf="mediaURL && mediaURL !== '#mediaUrl#' && !isCarousel"
    >
      <img
        class="preview-media image"
        [src]="mediaURL"
        *ngIf="mediaFileType === mediaType.IMAGE"
        alt="preview"
      />
      <video
        controls
        controlsList="nodownload"
        class="preview-media video"
        *ngIf="mediaFileType === mediaType.VIDEO"
      >
        <source [src]="mediaURL" type="video/mp4" />
        <track kind="subtitles" src="" srclang="en" label="English subtitles" />
      </video>
    </div>
    <p class="content" *ngIf="content">
      <span *ngIf="!isTextExpanded">{{
        content.length > maxLetterCount
          ? content.slice(0, maxLetterCount) + "... "
          : content
      }}</span>
      <span *ngIf="isTextExpanded">{{ content }} </span>
      <p-button
        *ngIf="content.length > maxLetterCount"
        styleClass="p-button-link p-0 expand-text-btn"
        label="{{ isTextExpanded ? 'Read less' : 'Read more' }}"
        (click)="toggleText()"
        (keydown)="$event.preventDefault()"
      >
      </p-button>
    </p>
    <p class="footer" *ngIf="footer && !isCarousel">
      {{ footer }}
    </p>
    <ng-container *ngIf="ctaButtons?.length && !isCarousel">
      <ng-container *ngFor="let button of ctaButtons; let i = index">
        <div
          class="d-flex align-items-center justify-content-center"
          [ngClass]="{
            'cta-button-1': i === 0,
            'cta-button-2': i === 1,
            'cta-button-3': i === 2
          }"
          *ngIf="button.buttonLabel && i < 3"
        >
          <img
            src="{{
              button.buttonAction === 'QUICK_REPLY'
                ? icons.REPLY
                : icons.OPEN_URL
            }}"
            *ngIf="i < 2 && button.buttonAction !== buttonActionType.CALL_BACK"
            alt="URL"
          />
          <img
            src="{{
              button.buttonAction === 'QUICK_REPLY'
                ? icons.REPLY
                : icons.OPEN_URL
            }}"
            *ngIf="
              i === 2 &&
              button.buttonAction !== buttonActionType.CALL_BACK &&
              ctaButtons &&
              ctaButtons.length <= 3
            "
            alt="URL"
          />
          <span
            class="option-class"
            *ngIf="
              i === 2 &&
              button.buttonAction !== buttonActionType.CALL_BACK &&
              ctaButtons &&
              ctaButtons.length > 3
            "
          >
            <i class="bi bi-list-ul alloptions-icon"></i>
          </span>

          <i
            class="bi bi-telephone-inbound-fill callback-icon"
            *ngIf="button.buttonAction === buttonActionType.CALL_BACK"
          ></i>
          <span>{{
            i < 2
              ? button.buttonLabel
              : i === 2 && ctaButtons && ctaButtons.length <= 3
              ? button.buttonLabel
              : "See all options"
          }}</span>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="carousel" *ngIf="isCarousel">
    <div class="preview-carousel">
      <div class="carousel-cards d-flex">
        <div
          *ngFor="let section of carouselUICards; let i = index"
          class="cards"
        >
          <ng-container
            *ngIf="section.mediaURL && section.mediaURL !== '#mediaUrl#'"
          >
            <img
              class="preview-media image"
              [src]="section.mediaURL"
              *ngIf="section.mediaType?.toUpperCase() === mediaType.IMAGE"
              alt="Preview"
            />
            <video
              controls
              controlsList="nodownload"
              class="preview-media video"
              *ngIf="section.mediaType?.toUpperCase() === mediaType.VIDEO"
            >
              <source [src]="section.mediaURL" type="video/mp4" />
              <track
                [src]="section.mediaURL"
                kind="subtitles"
                srclang="en"
                label="English"
              />
            </video>
          </ng-container>
          <p *ngIf="section.content" class="card-text">
            {{ section.content }}
          </p>
          <ng-container *ngIf="section?.buttons?.length">
            <ng-container *ngFor="let button of section.buttons; let j = index">
              <div
                *ngIf="button.label"
                class="d-flex justify-content-center align-items-center carousel-btn"
              >
                <img
                  src="{{ icons.OPEN_URL }}"
                  *ngIf="
                    button.buttonAction?.toLowerCase() === 'static cta' ||
                    button.buttonAction?.toLowerCase() === 'dynamic cta'
                  "
                  alt="URL"
                />
                <img
                  src="{{ icons.REPLY }}"
                  *ngIf="button.buttonAction?.toLowerCase() === 'quick reply'"
                  alt="QUICK_REPLY"
                />
                <i
                  class="bi bi-telephone-inbound-fill callback-icon"
                  *ngIf="button.buttonAction?.toLowerCase() === 'call back'"
                ></i>
                <span class="carousel-button-label">{{ button.label }}</span>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
