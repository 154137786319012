import { FilterMatchMode } from 'primeng/api';
import { AuthConstants } from '../authorization/auth.constants';
import { Icons } from 'src/app/shared/utilities/icons';

export const PREVIEW_CASES = {
  WHATSAPP: 'WHATSAPP',
  SMS: 'SMS',
  EMAIL: 'EMAIL',
};

export const DASHBOARD_SECTIONS = {
  CAMPAIGNS: 'campaigns',
  SEGMENTS: 'segments',
  TEMPLATES: 'templates',
};

export const CAMPAIGN_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
};

export const APPROVAL_TYPE = {
  LEGALLY: 'LEGALLY',
  TRAI: 'TRAI',
  META: 'META',
  DPM: 'DPM',
};

export const MEDIA_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  DOC: 'DOCUMENT',
};

export enum TemplateStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  PENDING = 'PENDING',
  RETIRED = 'RETIRED',
  ALL = 'ALL',
  REJECTED = 'REJECTED',
}

export const MODE_OF_EXECUTION = {
  NORMAL: 'NORMAL',
  ORCHESTRATE: 'ORCHESTRATE',
};

export const EXTENSION = {
  PNG: 'PNG',
  JPEG: 'JPEG',
  JPG: 'JPG',
  PDF: 'PDF',
  EML: 'EML',
  CSV: 'CSV',
  DOC: 'DOC',
  XLS: 'XLS',
  XLSX: 'XLSX',
  MSG: 'MSG',
  MP4: 'MP4',
};

export const UPLOAD = {
  MAX_SIZE: 5000000,
  MAX_UPLOADS: 1,
  CONCURRENCY: 1,
  EMAIL_ATTACHMENT: {
    MAX_SIZE: 9500000,
  },
  MEDIA_ATTACHMENT: {
    MAX_SIZE: 15000000,
    IMAGE_MAX_SIZE: 5000000,
    VIDEO_MAX_SIZE: 10000000,
    DOC_MAX_SIZE: 10000000,
  },
  TEMPLATE_ATTACHMENT: {
    MAX_SIZE: 9000000,
  },
  FORM_ATTACHMENT: {
    LOGO_MAX_SIZE: 200000,
    BANNER_MAX_SIZE: 500000,
  },
};

export const INPUT = {
  APPROVALS_REQ: 3,
  MAX_LENGTH: {
    TEXTAREA: 1024,
    SMS: 160,
  },
  FORM: {
    BASE_SIZE: 1000,
    FORM_NAME: {
      MIN_LENGTH: 2,
      MAX_LENGTH: 100,
      REGEX: /^[A-Za-z0-9/\-+.,&();:*? ]+$/,
    },
    FORM_DESC: {
      MIN_LENGTH: 2,
      MAX_LENGTH: 2000,
    },
    FOOTER: {
      MAX_LENGTH: 4000,
    },
    REGEX: {
      ONLY_NUMBERS: /^[0-9]*$/,
      ALLOW_ALL: /^[A-Za-z0-9@~`!#$%^&*()_=+\\';:\"\\/?>.<{}\[\]|,\s-]*$/i,
    },
    CAMAIGN_NAME: {
      MIN_LENGTH: 2,
      MAX_LENGTH: 50,
      REGEX: /^[A-Za-z0-9-_]+$/,
    },
  },
};

export const ROUTES = {
  HOME: 'dashboard',
  TEMPLATES: 'templates',
  SEGMENTS: 'segments',
  CAMPAIGNS: 'campaigns',
  CAMPAIGN_SCHEDULER: 'campaign-scheduler',
  REPORTS: 'reports',
  FILE: 'upload-file',
  FORM_DESIGN: 'form-design',
  CONFIGURATION: 'configuration',
  WABA_LISTING: 'waba-listing',
  RELOAD_CACHE: 'reload-cache',
  QLIK_SENSE_REPORT: 'qlik-sense',
  VIEW: 'view',
};

export const FILE_TYPE = {
  EMAIL: 'EMAIL',
  WHATSAPP_IMAGE: 'IMAGE',
  WHATSAPP_VIDEO: 'VIDEO',
  WHATSAPP_DOCUMENT: 'DOCUMENT',
  VARIABLE: 'VARIABLE',
  LOGO: 'LOGO',
  BANNER: 'BANNER',
};

export const GRID_DATA_TYPE = {
  MORE: 'more',
  DATE: 'date',
  LINK: 'link',
  TEXT: 'text',
  NUMBER: 'number',
  DROPDOWN: 'dropdown',
  MULTISELECT: 'multiselect',
  RESPONSE_TYPE: 'responseType',
  IMAGE: 'image',
  RESPONSE_VALUE: 'responseValue',
  DEPENDENT_SEQUENCE: 'dependentSequence',
  SKIP_SEQUENCE: 'skipSequence',
  SCRIPT: 'script',
  ACTION_BUTTON: 'actionButton',
};

export const matchModeOptionsForFilter = [
  {
    label: 'Equals',
    value: FilterMatchMode.EQUALS,
  },
  {
    label: 'Contains',
    value: FilterMatchMode.CONTAINS,
  },
  {
    label: 'Not Contains',
    value: FilterMatchMode.NOT_CONTAINS,
  },
];

export const Regex = {
  TEXTAREA_REGEX: /^[^@{}\[\]=!~^$%<>`"]*$/,
  TEXT_REGEX: /^[^@#{}\[\]=!~^$%<>`"]*$/,
  WABA_NAME_REGEX: /^[a-zA-Z0-9_]+$/,
  WABA_DESC: /^[a-zA-Z0-9 ]+$/,
  META_TEMPLATE_NAME: /^[A-Za-z0-9_]+$/,
};

export const GRID_NAME = {
  TEMPLATE_LISTING: 'TEMPLATE_LISTING',
  WABA_LISTING: 'WABA_LISTING',
  FORM_LISTING: 'FORM_LISTING',
  SEGMENT_LISTING: 'SEGMENT_LISTING',
  SCHEDULER_LISTING: 'SCHEDULER_LISTING',
  CAMPAIGN_LISTING: 'CAMPAIGN_LISTING',
  FILE_UPLOAD_LISTING: 'FILE_UPLOAD_LISTING',
};

export const SIDE_NAV = [
  {
    name: 'Web Form Designer',
    route: ROUTES.FORM_DESIGN,
    activeIcon: 'assets/svg/common/web_form_active.svg',
    inActiveIcon: 'assets/svg/common/web_form_inactive.svg',
    permission: AuthConstants.WEB_FORM,
  },
  {
    name: 'Template',
    route: ROUTES.TEMPLATES,
    activeIcon: 'assets/svg/common/template_active.svg',
    inActiveIcon: 'assets/svg/common/template_inactive.svg',
    permission: AuthConstants.TEMPLATE,
  },
  {
    name: 'Campaigns',
    route: ROUTES.CAMPAIGNS,
    activeIcon: 'assets/svg/common/campaign_active.svg',
    inActiveIcon: 'assets/svg/common/campaign_inactive.svg',
    permission: AuthConstants.CAMPAIGN,
  },
  {
    name: 'Target Segment',
    route: ROUTES.SEGMENTS,
    activeIcon: 'assets/svg/common/segment_active.svg',
    inActiveIcon: 'assets/svg/common/segment_inactive.svg',
    permission: AuthConstants.TARGET_SEGMENT,
  },
  {
    name: 'Campaign Scheduler',
    route: ROUTES.CAMPAIGN_SCHEDULER,
    activeIcon: 'assets/svg/common/campaign_scheduler_active.svg',
    inActiveIcon: 'assets/svg/common/campaign_scheduler_inactive.svg',
    permission: AuthConstants.CAMPAIGN_SCHEDULER,
  },
  {
    name: 'Reports',
    route: ROUTES.REPORTS,
    activeIcon: 'assets/svg/common/reports_active.svg',
    inActiveIcon: 'assets/svg/common/reports_inactive.svg',
    permission: AuthConstants.REPORT,
    // showChildren: false,
    // children: [
    //   // {
    //   //   name: 'CDP Reports',
    //   //   route: ROUTES.VIEW,
    //   //   permission: AuthConstants.REPORT_CDP,
    //   // },
    //   // {
    //   //   name: 'Customer Responses',
    //   //   route: null,
    //   //   permission: AuthConstants.REPORT_CUSTOMER_RESPONSES,
    //   // },
    //   {
    //     name: 'Qlik Sense Reports',
    //     route: ROUTES.QLIK_SENSE_REPORT,
    //     permission: AuthConstants.REPORT_QLIK_SENSE,
    //   },
    // ],
  },
  {
    name: 'Configuration',
    route: ROUTES.CONFIGURATION,
    activeIcon: 'assets/svg/common/configuration_active.svg',
    inActiveIcon: 'assets/svg/common/configuration_inactive.svg',
    permission: AuthConstants.CONFIGURATION,
    showChildren: false,
    children: [
      {
        name: 'WABA Number',
        route: ROUTES.WABA_LISTING,
        permission: AuthConstants.CONFIGURATION_WABANUMBER,
      },
      {
        name: 'Reload Cache',
        route: ROUTES.RELOAD_CACHE,
        permission: AuthConstants.CONFIGURATION_RELOADCACHE,
      },
      // {
      //   name: 'Email',
      //   route: null,
      //   permission: AuthConstants.CONFIGURATION_EMAIL,
      // },
    ],
  },
];

export const CommonMessages = {
  ERROR: {
    NOT_ENOUGH_PERMISSION:
      'You do not have enough screen permissions to access this application. Please contact your Admin.',
    SOME_ERROR_OCCURED: 'Some error occured, please contact Admin.',
    SERVICE_UNAVILABLE_ERROR:
      'Resource Unavailable. Please try after some time.',
  },
};

export const CHANNELS_MAP: any /**NOSONAR */ = {
  SMS: {
    key: 'SMS',
    icon: Icons.SMS,
    label: 'SMS',
  },
  WHATSAPP: {
    key: 'WHATSAPP',
    icon: Icons.WHATSAPP,
    label: 'WHATSAPP',
  },
  EMAIL: {
    key: 'EMAIL',
    icon: Icons.EMAIL,
    label: 'EMAIL',
  },
};

export const SEGMENTS_MAP: any = {
  Manual: {
    key: 'Manual',
    icon: Icons.STANDALONE,
    label: 'Manual',
  },
  Automated: {
    key: 'Automated',
    icon: Icons.LQS,
    label: 'Automated',
  },
};

export const API_INTEGRATED = 'API Integrated';

export const SEGMENT_TYPE = {
  MANUAL: 'Manual',
  AUTOMATED: 'Automated',
};

export const TEST_TEMPLATE = {
  LANDING: 'LANDING',
  PLACEHOLDER: 'PLACEHOLDER',
  MEDIA_WEB: 'MEDIA_WEB',
  CTA: 'CTA',
  CAROUSEL: 'CAROUSEL',
  RESPONSES: 'RESPONSES',
  SUCCESS: 'SUCCESS',
};

export const REGEX = {
  ALPHANUMERIC_WITH_SUH: /^[A-Za-z0-9/\-_ ]+$/,
};

export const OrchestrationOptions = {
  TCL: 'TCL',
  INHOUSE: 'Inhouse',
};

export const SegmentButtonType = {
  GENERATE: 'GENERATE',
  MAP: 'MAP',
  UPLOAD: 'UPLOAD',
};
