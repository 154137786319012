import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IModal, ModalButtonType } from '../../interfaces/modal.interface';
import { FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements AfterViewInit {
  @Input() config: IModal;
  @Input() formURL: string;
  @Input() form?: FormGroup;
  @Output() buttonType: EventEmitter<string> = new EventEmitter();
  @Output() decomissionEv: EventEmitter<string> = new EventEmitter();
  decommissionReason: string;
  modalButtonType = ModalButtonType;

  constructor(
    private readonly modalService: BsModalService,
    private readonly commonService: CommonService
  ) {}

  ngAfterViewInit(): void {
    document
      .getElementsByClassName('modal-dialog')?.[0]
      ?.classList?.add('modal-dialog-centered');
  }

  onButtonClick(buttonType: string): void {
    this.buttonType.emit(buttonType);
    this.decommissionReason && this.decomissionEv.emit(this.decommissionReason);
  }

  close(): void {
    this.buttonType.emit(this.modalButtonType.CROSS);
    this.modalService.hide();
  }

  isDisabled(): boolean {
    if (this.form) {
      if (this.config?.modalFooter?.checkFormValidation) {
        return this.commonService.templateFormValidity;
      }
      return this.form.invalid;
    }
    return false;
  }
}
