const baseUrl = 'https://d2vluxb694imb6.cloudfront.net/dev/maruti';

export const environment = {
  production: false,
  BASE_URL: `${baseUrl}`,
  COMM_BASE_URL: `${baseUrl}/communication-service/v1/`,
  FORM_BASE_URL: `${baseUrl}/form/v1/`,
  CAMPAIGN_BASE_URL: `${baseUrl}/campaign/v1/`,
  ESB_BASE_URL: `${baseUrl}/esb-service/`,
  COMPOSITE_BASE_URL: `${baseUrl}/composite/v1/`,
  REDIRECT_URL: 'https://dev.campaign.communicationmanager.in/dashboard',
  LOGOUT_URL: 'https://dev.campaign.communicationmanager.in/login',
  AD_API_KEY: '5jtq43gpt37ioppr6jc55e1mna',
  APIKEY: 'LS8LArIgBt34vKrCFOIYp4skbhj7yUAA35UaO2tn',
  FORM_PREVIEW_URL: 'https://dev.formsmarutisuzuki.com',
  QLIK_SENSE_REPORT_URL: 'https://dev-reports.communicationmanager.in',
  FORM_DEFAULT_LOGO_URL:
    'https://d1fcy6e4845263.cloudfront.net/logos/LogoMSIL-20240701-0636241-main.png',
};
