import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from 'src/app/core/constants/api';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
  IDepartmentsResponse,
  IDivisionsResponse,
  IOrganizationsResponse,
  IVerticalsResponse,
} from '../interfaces/org-details.interface';

@Injectable()
export class OrgDetailsService {
  constructor(private readonly http: HttpClient) {}

  getOrganizationsList(): Observable<IOrganizationsResponse> {
    return this.http.get<IOrganizationsResponse>(
      `${environment.CAMPAIGN_BASE_URL}${API_URL.MASTER_ORGANIZATIONS}`
    );
  }

  getVerticalsList(organization: string): Observable<IVerticalsResponse> {
    return this.http.get<IVerticalsResponse>(
      `${environment.CAMPAIGN_BASE_URL}${API_URL.MASTER_VERTICALS}?organization=${organization}`
    );
  }

  getDivisionsList(
    organization: string,
    vertical: string
  ): Observable<IDivisionsResponse> {
    return this.http.get<IDivisionsResponse>(
      `${environment.CAMPAIGN_BASE_URL}${API_URL.MASTER_DIVISIONS}?organization=${organization}&vertical=${vertical}`
    );
  }

  getDepartmentsList(
    organization: string,
    vertical: string,
    division: string
  ): Observable<IDepartmentsResponse> {
    return this.http.get<IDepartmentsResponse>(
      `${environment.CAMPAIGN_BASE_URL}${API_URL.MASTER_DEPARTMENTS}?organization=${organization}&vertical=${vertical}&division=${division}`
    );
  }
}
