import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Icons } from '../../utilities/icons';
import {
  PREVIEW_CASES,
  MEDIA_TYPE,
  EXTENSION,
} from 'src/app/core/constants/common';
import { ICTAButton } from 'src/app/modules/templates/interfaces/template-list-response.interface';
import { ButtonActionType } from 'src/app/modules/templates/interfaces/create-template.interface';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss'],
})
export class PreviewModalComponent implements OnInit, AfterViewInit {
  title: string;
  content: string;
  footer: string;
  icons = Icons;
  mediaType = MEDIA_TYPE;
  channelType: string;
  emailSubject: string;
  channelTypeImg: string;
  previewCase = PREVIEW_CASES;
  ctaButtons?: ICTAButton[] = [];
  mediaURL?: string;
  mediaFileType: string;
  isCarousel: boolean;
  carouselCards: any[] = [];
  buttonActionType = ButtonActionType;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {
    this.channelTypeImg =
      this.channelType === PREVIEW_CASES.EMAIL
        ? this.icons.EMAIL_PREVIEW
        : this.channelType === PREVIEW_CASES.SMS
        ? this.icons.SMS_PREVIEW
        : this.icons.WHATSAPP_PREVIEW;

    if (this.mediaURL) {
      const extension = this.mediaURL.split('.').pop()?.toUpperCase();
      if (extension === EXTENSION.MP4) {
        this.mediaFileType = MEDIA_TYPE.VIDEO;
      } else if (
        extension === EXTENSION.PNG ||
        extension === EXTENSION.JPG ||
        extension === EXTENSION.JPEG
      ) {
        this.mediaFileType = MEDIA_TYPE.IMAGE;
      } else {
        this.mediaFileType = MEDIA_TYPE.DOC;
      }
    }

    if (this.ctaButtons?.length) {
      this.ctaButtons = this.ctaButtons.map((cta) => {
        return {
          ...cta,
          buttonLabel: cta.label,
          buttonAction: cta.buttonAction?.toUpperCase().replace(/ /g, '_'),
        };
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      document
        .getElementsByClassName('modal-dialog')[0]
        ?.classList?.add('modal-preview');
    });
  }
}
