export const CommonAuthConstants = {
  common: 'cm:menu',
};

export const AuthConstants = {
  WEB_FORM: 'web-form',
  TEMPLATE: 'template',
  CAMPAIGN: 'campaign',
  TARGET_SEGMENT: 'target-segment',
  CAMPAIGN_SCHEDULER: 'campaign-scheduler',

  CONFIGURATION: 'configuration',
  CONFIGURATION_WABANUMBER: 'configuration:waba-number',
  CONFIGURATION_RELOADCACHE: 'configuration:reload-cache',
  CONFIGURATION_EMAIL: 'configuration:email-id',

  REPORT: 'report',
  REPORT_CDP: 'report:cdp',
  REPORT_CUSTOMER_RESPONSES: 'report:customer-responses',
  REPORT_QLIK_SENSE: 'report:qlik-sense',
};
